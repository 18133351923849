import { Link, useLocation, useNavigate, useSearchParams } from '@remix-run/react'
import { InfoIcon, CheckSquare } from 'lucide-react'
import { getTranscriptStatus, type Transcript } from '~/lib/transcript/queries'
import { Tooltip, TooltipContent, TooltipTrigger } from '~/core/ui/Tooltip'
import Badge, { type Color as BadgeColor } from '~/core/ui/Badge'
import useTranscriptionFilesTable from '~/core/hooks/use-transcription-files-table'
import { ProjectActionSettings } from './ProjectActionSettings'
import { cn } from '~/core/generic/shadcn-utils'
import { useQuery } from '@tanstack/react-query'
import useSupabase from '~/core/hooks/use-supabase'
import { StatusBadge } from '../_app.transcribe.$id.transcription.$transcriptionId/components/StatusBadge'
import { Checkbox } from '~/core/ui/Checkbox'
import Button from '~/core/ui/Button'
import { useState, useEffect, createContext, useContext } from 'react'

// Create a context for selected transcripts
type SelectedTranscriptsContextType = {
  selectedTranscripts: string[];
  toggleTranscript: (id: string) => void;
  isSelected: (id: string) => boolean;
  selectAllTranscripts: (ids: string[]) => void;
  deselectAllTranscripts: () => void;
};

const SelectedTranscriptsContext = createContext<SelectedTranscriptsContextType>({
  selectedTranscripts: [],
  toggleTranscript: () => {},
  isSelected: () => false,
  selectAllTranscripts: () => {},
  deselectAllTranscripts: () => {},
});

export const useSelectedTranscripts = () => useContext(SelectedTranscriptsContext);

const columns = [
  {
    accessorKey: 'title',
    header: 'Title',
  },
  {
    accessorKey: 'uploadDate',
    header: 'Upload date',
  },
  {
    accessorKey: 'status',
    header: '',
  },
  {
    accessorKey: 'action',
    header: <InfoTooltip />,
  },
] as const
function InfoTooltip() {
  return (
    <Tooltip>
      <TooltipTrigger>
        <InfoIcon className="cursor-pointer" />
      </TooltipTrigger>

      <TooltipContent sideOffset={4}>
        Mark the transcripts you want to analyze
      </TooltipContent>
    </Tooltip>
  )
}

interface FileTableRowProps {
  children?: React.ReactNode;
  id: string;
  title: string;
  uploadDate: string;
  status: Transcript["status"];
  className?: string;
}
const POLL_INTERVAL = 5000 // in ms

function FileTableRow({
  children,
  title,
  id,
  status: initialStatus,
  uploadDate,
  className,
}: FileTableRowProps) {
  const client = useSupabase()
  const { data: status } = useQuery({
    queryKey: ['transcription-status', id],
    queryFn: () =>
      getTranscriptStatus(client, { transcriptionId: id }).then(
        (res) => res.data?.status,
      ),
    initialData: initialStatus,
    refetchInterval: ({state: {data: status}}) => {
      if (status !== 'COMPLETED' && status !== 'ERROR') {
        return POLL_INTERVAL;
      }
      return false;
    },
    enabled: initialStatus !== "COMPLETED" && initialStatus !== "ERROR"
  });
  const location = useLocation();
  const isNotCompleted = status !== "COMPLETED" && status !== "ERROR" 
  return (
    <tr
      className={cn(
        className,
        'hover:bg-gray-50 transition-colors',
        isNotCompleted && '[&_td]:opacity-50',
      )}
    >
      <td className="px-6 py-4 whitespace-nowrap">
        <Link to={`${location.pathname}/transcription/${id}`} className='font-medium text-blue-600 hover:text-blue-800 hover:underline'>{title}</Link>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{uploadDate}</td>
      <td className='px-6 py-4 whitespace-nowrap'>
        <div className="flex justify-center">
          {status && <StatusBadge status={status} />}
        </div>
      </td>

      {children}
    </tr>
  );
}

// Provider component for the selected transcripts context
export function SelectedTranscriptsProvider({ 
  children 
}: { 
  children: React.ReactNode 
}) {
  const [selectedTranscripts, setSelectedTranscripts] = useState<string[]>([]);
  
  const toggleTranscript = (id: string) => {
    setSelectedTranscripts(prev => 
      prev.includes(id) 
        ? prev.filter(transcriptId => transcriptId !== id)
        : [...prev, id]
    );
  };
  
  const isSelected = (id: string) => selectedTranscripts.includes(id);
  
  const selectAllTranscripts = (ids: string[]) => {
    setSelectedTranscripts(prev => {
      const newSelection = [...prev];
      ids.forEach(id => {
        if (!newSelection.includes(id)) {
          newSelection.push(id);
        }
      });
      return newSelection;
    });
  };
  
  const deselectAllTranscripts = () => {
    setSelectedTranscripts([]);
  };
  
  return (
    <SelectedTranscriptsContext.Provider 
      value={{ 
        selectedTranscripts, 
        toggleTranscript, 
        isSelected, 
        selectAllTranscripts, 
        deselectAllTranscripts 
      }}
    >
      {children}
    </SelectedTranscriptsContext.Provider>
  );
}

export default function FilesTable({
  transcripts,
  isDefault,
}: {
  transcripts: Transcript[];
  isDefault?: boolean;
}) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    data,
    moveTranscript,
    deleteTranscript,
    editTranscript,
  } = useTranscriptionFilesTable({ transcripts });
  
  // State for selected transcripts
  const [selectedTranscriptIds, setSelectedTranscriptIds] = useState<string[]>([]);

  // Check if all completed transcripts are selected
  const completedTranscripts = data.filter(row => row.status === 'COMPLETED');
  const allSelected = completedTranscripts.length > 0 && 
                      completedTranscripts.every(row => selectedTranscriptIds.includes(row.id));

  // Toggle selection for a single transcript
  const toggleTranscriptSelection = (transcriptId: string) => {
    setSelectedTranscriptIds(prev => 
      prev.includes(transcriptId)
        ? prev.filter(id => id !== transcriptId)
        : [...prev, transcriptId]
    );
  };

  // Toggle all completed transcripts
  const toggleAllTranscripts = () => {
    if (allSelected) {
      // Deselect all
      setSelectedTranscriptIds(prev => 
        prev.filter(id => !completedTranscripts.some(t => t.id === id))
      );
    } else {
      // Select all completed transcripts
      setSelectedTranscriptIds(prev => {
        const completedIds = completedTranscripts.map(t => t.id);
        return [...new Set([...prev, ...completedIds])];
      });
    }
  };

  // Add selected transcripts to URL only when analyzing
  const startAnalysis = () => {
    // Add all selected transcripts to URL and navigate to analysis page
    const newParams = new URLSearchParams(searchParams);
    
    // Clear existing transcriptionId params
    newParams.delete('transcriptionId');
    
    // Add current selections
    selectedTranscriptIds.forEach(id => {
      newParams.append('transcriptionId', id);
    });
    
    // setSearchParams(newParams, { replace: true });
    
    // Optionally, you can redirect to the analysis page here
    navigate(`./analysis?${newParams.toString()}`);
  };

  return (
    <div className="bg-white shadow-sm min-h-full border rounded-lg overflow-hidden">
      <div className="flex justify-between items-center p-4 border-b">
        <h3 className="font-medium text-gray-700">Your Transcripts</h3>
        <div className="flex items-center gap-3">
          <Button 
            variant="outline" 
            size="sm" 
            onClick={toggleAllTranscripts}
            className="flex items-center gap-2 text-sm"
          >
            <CheckSquare className="h-4 w-4 mr-1" />
            {allSelected ? 'Deselect All' : 'Select All'}
          </Button>
          {selectedTranscriptIds.length > 0 && (
            <Button 
              variant="default"
              size="sm"
              onClick={startAnalysis}
              className="text-sm"
            >
              Analyze Selected ({selectedTranscriptIds.length})
            </Button>
          )}
        </div>
      </div>
      <div className="overflow-x-auto overflow-y-hidden">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              {columns
                .filter(
                  ({ accessorKey }) => !isDefault || accessorKey !== 'action',
                )
                .map(({ header }, idx) => (
                  <th
                    key={idx}
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap last:text-right"
                  >
                    {header}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {data.map((row, rowIdx) => (
              <FileTableRow 
                key={row.id} 
                {...row}
              >
                <td className="px-6 py-4 whitespace-nowrap text-right text-sm">
                  <ProjectActionSettings
                    status={row.status}
                    changeIsCheckedStatus={(id) => toggleTranscriptSelection(id)}
                    isChecked={selectedTranscriptIds.includes(row.id)}
                    transcriptionId={row.id}
                    transcription={row.title}
                    moveTranscript={moveTranscript}
                    deleteTranscript={deleteTranscript}
                    editTranscript={editTranscript}
                  />
                </td>
              </FileTableRow>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
