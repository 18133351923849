import { useFetcher } from "@remix-run/react"
import { useMemo } from "react"
import { Transcript } from "~/lib/transcript/queries"

interface UseTranscriptionFilesTableParams {
    transcripts: Transcript[]
}

export function formatDate(inputDateStr: string): string {
  const date = new Date(inputDateStr)

  // Format the date to "DD/MM/YYYY, HH:mm:ss"
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  }

  const formattedDate = date.toLocaleString('en-GB', options).replace(',', '')
  return formattedDate // Output: "16/10/2024, 13:41:05"
}


export default function useTranscriptionFilesTable({
    transcripts
}: UseTranscriptionFilesTableParams) {
  // We no longer handle transcript selection in this hook
  // That's now managed by the component state directly

  const fetcher = useFetcher()
  const data = useMemo(() => transcripts?.map(item =>
    ({
      id: item.id,
      title: item.title,
      status: item.status,
      uploadDate: formatDate(item.created_date),
    })) ?? [], [transcripts])

  function deleteTranscript(id: string | string[]) {
    const formData = new FormData()
    if (Array.isArray(id)) {
      id.forEach((transcriptId) => {
        formData.append('transcriptId', transcriptId)
      })
    }
    else {
      formData.append('transcriptId', id)
    }

    fetcher.submit(formData, { method: 'DELETE' })
  }

  function editTranscript({ id, title }: { id: string, title: string }) {
    const formData = new FormData()
    formData.append('transcriptId', id)
    formData.append('title', title)

    fetcher.submit(formData, { method: 'POST' })
  }

  function moveTranscript(
    { id, currentProjectId, destinationProjectId }: {
      id: string | string[]
      currentProjectId: string
      destinationProjectId: string
    },
  ) {
    const formData = new FormData()

    if (Array.isArray(id)) {
      id.forEach((val) => {
        formData.append('transcriptId', val)
      })
    }
    else {
      formData.append('transcriptId', id)
    }

    formData.append('currentProjectId', currentProjectId)
    formData.append('destinationProjectId', destinationProjectId)

    fetcher.submit(formData, { method: 'PUT' })
  }

  return {
    data,
    editTranscript,
    moveTranscript,
    deleteTranscript
  }
}