import { Link, useLoaderData, useLocation } from "@remix-run/react"
import { useMemo, useState } from "react"
import { Project, Transcript } from "~/lib/transcript/queries"
import ConfirmDeleteTranscriptModal from "./ConfirmDeleteTranscriptModal"
import EditTranscriptModal from "./EditTranscriptModal"
import { Checkbox } from "~/core/ui/Checkbox"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "~/core/ui/Dropdown"
import { EllipsisVerticalIcon } from "lucide-react"
import MoveFileIntoProjectModal from "~/core/ui/MoveFileIntoProjectModal"

type ModalType = 'edit' | 'delete' | 'move' | null

interface ProjectActionSettingsProps {
  status: Transcript["status"];
  transcriptionId: string
  transcription: string
  deleteTranscript: (id: string | string[]) => void
  editTranscript: (params: { id: string, title: string }) => void
  moveTranscript: (
    params: {
      id: string | string[]
      currentProjectId: string
      destinationProjectId: string
    }) => void
  isChecked: boolean
  changeIsCheckedStatus: (transcriptId: string) => void
}

export function ProjectActionSettings({
  status,
  transcriptionId,
  transcription,
  deleteTranscript,
  editTranscript,
  moveTranscript,
  isChecked,
  changeIsCheckedStatus,
}: ProjectActionSettingsProps) {
  const { projectsLabelList, project, transcripts } = useLoaderData<{
    projectsLabelList: { id: string, title: string }[]
    project: Project
    transcripts: Transcript[]
  }>()
  const [openModalType, setOpenModalType] = useState<ModalType>(null)
  const location = useLocation()
  // We no longer use searchParams for selection state
  const selectedTranscriptions: string[] = []

  // We just use the individual transcription ID since bulk operations
  // are now handled in the FilesTable component
  const transcriptionIdToPass = transcriptionId

  // We now just use the current transcription title directly
  const transcriptTitle = transcription
  return (
    <>
      <ConfirmDeleteTranscriptModal
        open={openModalType === 'delete'}
        onConfirm={() => deleteTranscript(transcriptionId)}
        transcriptTitle={transcriptTitle}
        setOpen={() => setOpenModalType(null)}
      />
      <EditTranscriptModal
        transcript={transcription}
        onConfirm={title => editTranscript({ title, id: transcriptionId })}
        open={openModalType === 'edit'}
        setOpen={() => setOpenModalType(null)}
      />
      <MoveFileIntoProjectModal
        open={openModalType === 'move'}
        onConfirm={destinationProjectId =>
          moveTranscript({
            currentProjectId: project.id,
            id: transcriptionId,
            destinationProjectId,
          })}
        projects={projectsLabelList}
        setOpen={() => setOpenModalType(null)}
      />
      <div className="flex items-center justify-end space-x-4">
        <Checkbox
          disabled={status !== 'COMPLETED'}
          checked={isChecked}
          onClick={() => changeIsCheckedStatus(transcriptionId)}
          className="rounded-sm border-gray-300 text-blue-600 focus:ring-blue-500"
        />

        <DropdownMenu>
          <DropdownMenuTrigger asChild={true}>
            <EllipsisVerticalIcon className="w-5 h-5 text-gray-500 hover:text-gray-700 cursor-pointer transition-colors" />
          </DropdownMenuTrigger>

          <DropdownMenuContent
            className="min-w-[160px] p-1 shadow-lg rounded-md border border-gray-200 bg-white"
            collisionPadding={{
              right: 20,
            }}
          >
            <DropdownMenuItem
              className="flex items-center px-3 py-2 text-sm rounded-sm cursor-pointer hover:bg-gray-100"
              onSelect={() => {
                setOpenModalType('move')
              }}
            >
              Move
            </DropdownMenuItem>
            <DropdownMenuItem
              className="flex items-center px-3 py-2 text-sm rounded-sm cursor-pointer hover:bg-gray-100"
              onSelect={() => {
                setOpenModalType('edit')
              }}
            >
              Edit
            </DropdownMenuItem>
            <DropdownMenuItem
              className="flex items-center px-3 py-2 text-sm rounded-sm cursor-pointer hover:bg-red-50 text-red-600"
              onSelect={() => {
                setOpenModalType('delete')
              }}
            >
              Delete
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </>
  )
}